<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
//import { productData } from "./data-products";
import appConfig from "@/app.config";

/**
 * Product-detail component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Product-detail",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  mounted() {
    this.id = this.$route.params.id;
    this.isEdit = this.$route.params.isEdit;
    this.isDelete = this.$route.params.isDelete;

    if (this.id) {
      sessionStorage.setItem("menuid", this.id); // Save to sessionStorage
      sessionStorage.setItem("isEdit", this.isEdit); // Save to sessionStorage
      sessionStorage.setItem("isDelete", this.isDelete); // Save to sessionStorage
    }

    this.getMenuDetailsByID(sessionStorage.getItem("menuid", this.id));
    this.editRight = sessionStorage.getItem("isEdit", this.isEdit);
    this.editDelete = sessionStorage.getItem("isDelete", this.isDelete);
  },
  data() {
    return {
      //productDetail: null,
      //productData: productData,
      title: "Restaurant Menu Details",
      items: [
        {
          text: "Restaurant Menu Details"
        },
        {
          text: "Restaurant Menu Details",
          active: true
        }
      ],
      dishDataArr: [],
      addonGroupData: [],
      getRestBranchs: [],
      cuisineFormatArr: [
        { cuisineFormatID: "1", cuisineFormatName: "Breakfast" },
        { cuisineFormatID: "2", cuisineFormatName: "Lunch" },
        { cuisineFormatID: "3", cuisineFormatName: "Dinner" },
        { cuisineFormatID: "4", cuisineFormatName: "Buffet" }
      ],
      // table for branch ////
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectMode: "multi",
      selected: [],
      dishData: "",
      fields: [
        {
          key: "restaurantName",
          sortable: true
        },
        {
          key: "address",
          sortable: true
        }
      ],
      editRight: "",
      deleteRight: ""
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  methods: {
    getMenuDetailsByID(menuID) {
      this.axios.post(this.$loggedRole+"/getDishesByID", { dishID: menuID }).then(response => {
        //console.log(response);
        this.dishDataArr = response.data.data;
        this.addonGroupData = response.data.data.addonGroupData;
        this.tableData = response.data.data.getRestBranchs;
        // console.log(this.tableData);
      });
    },
    ///// data table methods
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(items) {
      this.selected = items;
      // console.log(this.selected);
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2);
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2);
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-3">
                <div class="product-detail">
                  <div class="product-img">
                    <img
                      :src="
                        dishDataArr.image ? dishDataArr.image : $placeholder
                      "
                      alt
                      class="img-fluid mx-auto d-block"
                    />
                  </div>
                  <!-- <b-tabs pills vertical nav-wrapper-class="col-3">

                    <b-tab>
                      <template v-slot:title>
                        <img
                          :src="dishDataArr.thumbnail?dishDataArr.thumbnail:$placeholder"
                          alt
                          class="img-fluid mx-auto d-block tab-img rounded"
                        />
                      </template>
                      <div class="product-img">
                        <img
                          :src="dishDataArr.image?dishDataArr.image:$placeholder"
                          alt
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </b-tab>
                  </b-tabs> -->
                </div>
              </div>

              <div class="col-xl-9">
                <div class="mt-4 mt-xl-3 pl-xl-4">
                  <router-link
                    v-if="editRight == 1"
                    :to="{
                      name: 'restaurantMenuForm',
                      params: { id: this.dishDataArr.dishID, type: 'edit' }
                    }"
                  >
                    <button
                      class="btn btn-themeYellow"
                      style="position: absolute;right: 0;"
                    >
                      Edit <i class="uil-pen"></i>
                    </button>
                  </router-link>

                  <h5 class="font-size-14">
                    <a class="text-muted" href="#">{{
                      dishDataArr.restaurantName.name
                    }}</a>
                  </h5>
                  <h4 class="font-size-20 mb-3">{{ dishDataArr.title }}</h4>
                  <div
                    v-if="dishDataArr.calories !== null || dishDataArr != ''"
                    class="text-muted"
                  >
                    <span class="badge bg-warning font-size-14 me-1"
                      ><i class="mdi mdi-fire"></i>
                      {{ dishDataArr.calories }} Kcal
                    </span>
                  </div>
                  <span
                    v-for="(foodType, index) in dishDataArr.foodTypeArr"
                    v-bind:key="foodType.foodTypeName"
                    class="ribbonFoodType"
                  >
                    {{ foodType.foodTypeName }}
                    <span v-if="index + 1 < dishDataArr.foodTypeArr.length"
                      >,
                    </span>
                  </span>

                  <h5 class="mb-4 pt-2">
                    <span
                      >₹{{ dishDataArr.price }}
                      <span
                        v-if="dishDataArr.CGST == 0 && dishDataArr.IGST != 0"
                        class="text-muted font-size-14 me-1"
                        >(+ {{ dishDataArr.IGST.integralTaxName }} IGST)</span
                      >
                      <span
                        v-else-if="
                          dishDataArr.IGST == 0 && dishDataArr.CGST != 0
                        "
                        class="text-muted font-size-14 me-1"
                        >(+ {{ dishDataArr.SGST.stateTaxName }})</span
                      >
                    </span>
                  </h5>
                  <p class="text-muted mb-4">
                    {{ dishDataArr.description }}
                  </p>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="mt-3">
                        <h5 class="font-size-14">Cuisine Category :</h5>
                        <span> {{ dishDataArr.categoryID.categoryName }} </span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mt-3">
                        <h5 class="font-size-14">Cuisine Format :</h5>
                        <span> {{ dishDataArr.cuisineFormat }} </span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mt-3">
                        <h5 class="font-size-14">Dish Serving Time Slot :</h5>

                        <ul class="list-unstyled product-desc-list text-muted">
                          <li
                            v-for="(item,
                            index) in dishDataArr.dishServingTimeSlot"
                            :key="index"
                          >
                            {{ item.serveTimeName }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
    <div v-if="addonGroupData.length > 0" class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h6>Addons:</h6>
          <div
            v-for="(item, index) in addonGroupData"
            :key="index"
            style="margin-bottom: 16px;"
          >
            <h6>
              {{ item.addonGroup }} - {{ item.selectionType.selName }} -
              {{ item.required }}
            </h6>
            <div class="table-responsive mb-0">
              <table
                class="table table-striped mb-0"
                style="max-width: 500px;margin-top:15px;"
              >
                <!-- <thead>
                                          <tr>
                                          <td>UserName</td>
                                          <td>EmailID</td>
                                          <td>Phone No</td>
                                          <td>Role</td>
                                          
                                          </tr>
                                        </thead> -->
                <tbody v-for="(item2, index) in item.addonItems" :key="index">
                  <tr>
                    <td>{{ item2.title }}</td>
                    <td>{{ item2.foodTypeName }}</td>
                    <td>₹ {{ item2.price }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h6>Restaurant Branches:</h6>

          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              ref="selectableTable"
              :current-page="currentPage"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              :items="tableData"
              :per-page="perPage"
              :select-mode="selectMode"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              bordered
              hover
              outlined
              responsive
              selectable
              striped
              @filtered="onFiltered"
              @row-selected="onRowSelected"
            >
              <!-- <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true"><i class="far fa-check-square"></i></span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true"><i class="far fa-square"></i></span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
     -->

              <!-- <template v-slot:cell(Action)="row">
                <b-form-group>
                 
                     <input class="form-check-input" type="checkbox" id="branchselected"
                     :value="row.item.branchName" v-model="selectedBranchID" @change="selectedBranch();">
                </b-form-group>
              </template> -->
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="rows"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
